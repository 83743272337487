// Generated by Framer (1119174)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["osWGFlXvP", "N4IBWZnFD"];

const serializationHash = "framer-W04du"

const variantClassNames = {N4IBWZnFD: "framer-v-10s9b0j", osWGFlXvP: "framer-v-niwe5d"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {delay: 0, duration: 7, ease: [0, 0, 1, 1], type: "tween"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Progress Bar 1": "osWGFlXvP", "Progress Bar 2": "N4IBWZnFD"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "osWGFlXvP"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "osWGFlXvP", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppear1wg2lco = activeVariantCallback(async (...args) => {
setVariant("N4IBWZnFD")
})

useOnVariantChange(baseVariant, {default: onAppear1wg2lco})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-W04du", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-niwe5d", className)} data-framer-name={"Progress Bar 1"} data-highlight layoutDependency={layoutDependency} layoutId={"osWGFlXvP"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({N4IBWZnFD: {"data-framer-name": "Progress Bar 2"}}, baseVariant, gestureVariant)}><motion.div className={"framer-56ql4u"} layoutDependency={layoutDependency} layoutId={"WFKEG6bWI"} style={{backgroundColor: "var(--token-f07bd819-3132-4b59-b01d-c794567fa989, rgb(255, 255, 255))"}}/><motion.div className={"framer-1907e51"} layoutDependency={layoutDependency} layoutId={"DCTC4JW90"} style={{backgroundColor: "var(--token-014f44b0-4365-49a2-a3e1-43e76509186b, rgb(173, 255, 133))"}}/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-W04du [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-W04du .framer-1woxp4 { display: block; }", ".framer-W04du .framer-niwe5d { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 1px; justify-content: center; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: 400px; }", ".framer-W04du .framer-56ql4u { flex: none; height: 100%; left: 0px; overflow: visible; position: absolute; top: calc(50.00000000000002% - 100% / 2); width: 100%; z-index: 1; }", ".framer-W04du .framer-1907e51 { flex: none; height: 100%; left: 0px; overflow: visible; position: absolute; top: calc(50.00000000000002% - 100% / 2); width: 0%; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-W04du .framer-niwe5d { gap: 0px; } .framer-W04du .framer-niwe5d > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-W04du .framer-niwe5d > :first-child { margin-left: 0px; } .framer-W04du .framer-niwe5d > :last-child { margin-right: 0px; } }", ".framer-W04du.framer-v-10s9b0j .framer-1907e51 { width: 100%; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 1
 * @framerIntrinsicWidth 400
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"N4IBWZnFD":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 */
const Framerk7WnbbFMe: React.ComponentType<Props> = withCSS(Component, css, "framer-W04du") as typeof Component;
export default Framerk7WnbbFMe;

Framerk7WnbbFMe.displayName = "Slider Left / Slider Left Progress Bar";

Framerk7WnbbFMe.defaultProps = {height: 1, width: 400};

addPropertyControls(Framerk7WnbbFMe, {variant: {options: ["osWGFlXvP", "N4IBWZnFD"], optionTitles: ["Progress Bar 1", "Progress Bar 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(Framerk7WnbbFMe, [])